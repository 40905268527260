module.exports = {
  siteTitle: 'Robinson Istria', // <title>
  manifestName: 'Robinson House Istra',
  manifestShortName: 'Robinson', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/gatsby-starter-spectral/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Robinson House Istra',
  subHeading: "Croatia's first",
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-facebook',
      name: 'Facebook',
      url: 'https://facebook.com',
    },
    {
      style: 'brands',
      icon: 'fa-instagram',
      name: 'Instagram',
      url: 'https://instagram.com',
    },
    {
      style: 'solid',
      icon: 'fa-envelope',
      name: 'Email',
      url: 'mailto:test@example.com',
    },
  ],
};
